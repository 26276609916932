
@import url("https://use.fontawesome.com/releases/v5.6.3/css/all.css");
@font-face {
    font-family: 'Maledpan'; /*a name to be used later*/
    src: url('assets/fonts/Maledpan-Regular.otf'); /*URL to font*/
}
html,body {
	font-family: 'Maledpan';
}

main {
	/* padding-bottom: 80px; */
	padding-left: 20px;
	padding-right: 20px;

}
.btn-primary-green {
	background-color: #006b34 !important;
    border-color: #006b34 !important;
}
.breadcrumb {
	background-color: #fafbfc !important;
}
.navbar {
	border-top: 4px solid #006b34;
	/* border-top: 4px solid #006b34; */
	/* background-color: #006b34 !important; */
}

/* .nav-green {
	border:none !important;
	background-color: #006b34 !important;
} */


.footer {
	background-color: #000;
	color: #fff;
}

.footer > .container {
  	padding-right: 15px;
	padding-left: 15px;
}

code {
	font-size: 80%;
}
#content {
	background-color: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 50px;
}

#content-add {
	margin-top: 50px;
	background-color: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	padding: 20px;
}

#menu-item {
	border-right: 1px solid #C7C7C7;
}

/* @media (min-width: 576px) {
  .modal-dialog { max-width: none; }
} */

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
	color: #006b34 !important;
	background-color: #f3f7f5;
}

.page-item.active .page-link {
	background-color: #006b34;
	border-color: #006b34;
}
.page-link {
	color: #000;
}
.figure-img-add {
	border: 1px dashed #d5d5d5;
}
.delete-link {
	margin-top: 20px;
}

.dropdown-item,.nav-link{
	cursor: pointer;

}

/* .white{
	color: white !important;
} */

.no-pdd{
	padding: 0px !important;
}

.full-width{
    width: 100%;
}

.table{
	border-bottom: 1px solid #dee2e6;
}

.react-datepicker-wrapper,.react-datepicker__input-container{
	width: 100% !important;
}

.menu-item{
	width: 100%;
	background-color: transparent;
	text-align: left;
	padding: 8px;
	border: solid 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
}
.menu-item:hover{
	background-color: rgba(0, 0, 0, 0.05);
}

.status {
	margin-right: 12px;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	display: inline-block;
}
.status.void {
	background-color: #ff3b30;
}

.status.success {
	background-color: #006b34;
}

.fa-spin {
	animation-duration: 1s; 
}

.btn-120{
	min-width: 120px;
}

.title{
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.09;
	letter-spacing: normal;
	color: #ffffff;
}