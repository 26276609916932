.error{
    color: red;
    font-size: small;
}

.full{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef9c;
    opacity: 1;
    border: 0px solid #ced4da;
}

a.form-control:disabled,a.form-control[readonly]{
    color: #007bff;
    text-decoration: underline;
}
a.form-control:hover {
    color: #0056b3;
    text-decoration: underline;
}